<template>
    <v-form ref="form">
    <v-dialog v-model="dialog" class="ma-0 pa-0" max-width="600">
        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                height="80"
                class="custom-btn"
                block
            >   Pacientes {{ pacientes.length }}
                <v-icon
                    small
                    class="ma-3"
                    color="white"
                    size="60"
                >
                    mdi-account-heart
                </v-icon>
                
            </v-btn>
        </template>
        <v-card class="pa-8 ma-0">
            <v-row dense>
                <v-col class="text-right">
                    <v-icon @click="dialog=false">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-card-title>
                Pacientes 
            </v-card-title>
            <v-card-text>
                <v-data-table 
                    :items="filtro"
                    :headers="headers"
                    :loading="loading"
                    :search="search"
                    :hide-default-footer="true"
                    >  
                        <template v-slot:top>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model="search"
                                    label="Pesquisar"
                                    class="mx-4"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="10">
                                    <!-- <pre>
                                        {{pacientes }}
                                    </pre> -->
                                    <v-radio-group row label="Regulados" 
                                        v-model="orientacao" @change="filtrar()">
                                        <v-radio :value="true" label="SIM"></v-radio> 
                                        <v-radio :value="false" label="NÃO"></v-radio> 
                                        <v-radio :value="null" label="N/A"></v-radio> 
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn @click="refresh()" outlined><v-icon>mdi-refresh</v-icon></v-btn> 
                                </v-col>
                            </v-row>
                        </template>    
                    
                        <template v-slot:item.dt_nascimento="{item}">
                            {{ calcularIdade(item.dt_nascimento) }}
                        </template> 
                        <template v-slot:item.orientar="{item}">
                            {{ sim_nao(item.orientar) }}
                        </template>

                        <!-- <template v-slot:item.chamar="{item}">
                            <Chamar :current-item="item.id"/>
                        </template>     -->
                      
                        <template v-slot:item.ver="{item}">
                            <!-- <Paciente :current-item="item"/> -->
                            <router-link :to="`mutirao-sus-printer/${item.id}`" target="_blank"><v-icon>mdi-printer</v-icon></router-link>
                        </template>    
                    </v-data-table>
            </v-card-text>
        </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
    import api from '@/http'
    export default{
        name:'Lista',
        components:{
            // Chamar:()=>import('./Chamar.vue'),
            // Paciente:()=>import('./Paciente.vue'),
        },
        data:()=>({
            dialog:false,
            loading:false,
            search:'',
            msg:'',
            pacientes:[],
            filtro:[],
            orientacao:null,
            headers:[
                {text:'Prontuário',sorted:true,value:'id'},
                {text:'Nome',sorted:true,value:'nome'},
                {text:'Cpf',sorted:true,value:'cpf'},
                {text:'Data de Nasc.',sorted:true,value:'dt_nascimento'},
                {text:'Regulados',sorted:true,value:'orientar'},
                {text:'Ver',sorted:true,value:'ver'},
            ]
        }),
        methods:{
            refresh(){
                this.filtro = this.pacientes
                this.get_listas('mutirao-sus/pacientes/?etapa=todos', 'pacientes')
            },
            calcularIdade(anoNascimento) {
                const anoAtual = new Date().getFullYear();
                return anoAtual - new Date(anoNascimento).getFullYear();
            },
            get_listas(url, objeto){
                this.loading = true
                api.get(url)
                .then(res=>{
                    this[objeto]=res.data
                })
                .finally(()=>{this.loading = false})
            },
            sim_nao(val) {
                if(val===null || val==='' ){
                    return 'N/A'
                }
                return val?'SIM':'NÃO';
            },
            filtrar(){
               this.filtro = this.pacientes.filter(e=>{
                    if(e.orientar===this.orientacao){
                        return e
                    }else if(this.orientacao === 2){
                        return e
                    }
                })
            },
        },
        mounted(){
            this.get_listas('mutirao-sus/pacientes/?etapa=todos', 'pacientes')
        }
    }
</script>
<style>

.custom-card {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
}
</style>